import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';


const PerformanceContractPrior = () => {

const navigation = useNavigate();

  return (
    <>
      <Header label={<p className='py-0 my-0'>2022 Review Cycle</p>} />
      <main id="main" className="main">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='d-flex'>
                    <span className='field-label-icon pt-1'>
                      <i class="fa-solid fa-book fa-xl text-secondary"></i>
                    </span>
                    <div className='field-label flex-grow-1 px-4 font-weight-600 text-primary py-1'>2022 Performance Contract</div>
                  </div>

                  <div className='mx-5 mt-2 d-flex'>
                    <span className=' mt-1 mx-2'>Status:<span className='font-weight-600 text-primary ms-2'>Moderated & Closed</span></span>
                  </div>
                  <div className='ms-5 mt-3'>
                    <span className=' mt-1 mx-2'>Final Score:<span className='font-weight-600 text-primary ms-2'>Fully Achieved</span></span>
                  </div>

                  <div className='broken-lines mt-4'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
export default PerformanceContractPrior;
