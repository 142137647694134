import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const Culture = () => {



  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"}  label={"People Management/Culture"}/>
      <main id="main" className="main">

        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>

                  <div className='card-description'>
                    <p>
                      A workplace Culture helps to create a set of shared assumptions that define appropriate behaviour
                      for various situations, modes of interaction, and influence the extent to which employees identify
                      with our organisation.
                    </p>
                  </div>
                  <div className='content-block'>

                    <div className='h-4 card-content-headers mb-2'>
                    Employee Recognition
                    </div>
                    <p>The implementation of Recognition programmes in our organisation aims to cultivate a work environment where the contributions and successes of individuals are openly celebrated.</p>
                    <p>Recognition fosters a culture in which our employees feel valued and appreciated. Providing employees with regular recognition and commemorating their achievements (such as outstanding work, work anniversaries, promotions, birthdays, etc.) further enhances employee engagement, satisfaction, and commitment.</p>
                    <div className='link-container'>
                      <a href=''>Click here to access the Learning Management System</a>
                    </div>
                  </div>

                  <div className='content-block'>
                    <div className='h-4 card-content-headers mb-2'>
                      Employee Wellness

                    </div>

                    <p>Employee wellness ensures that employees' health and wellbeing, both in the workplace and at home, are effectively supported. It empowers employees to identify and attain their personal wellness goals, ultimately boosting employee engagement and productivity.</p>
                    <div className='link-container'>
                      <a href=''>
                        Click here to access the Employee Recognition system
                      </a>
                    </div>
                  </div>


                  <div className='content-block'>
                    <div className='h-4 card-content-headers mb-2'>
                      Issue Logging & Resolution
                    </div>
                    <p>The Issue Logging and Resolution system provides an efficient internal mechanism for addressing employee concerns in a manner perceived as fair, open, transparent, and supported by clear communication and tangible results. This system establishes a formalised tracking solution, ensuring that managers and HR are mutually accountable for addressing every employee concern in accordance with our organisation's policies and approach.</p>
                    <div className='link-container'>
                      <a href=''>
                        Click here to access the Issue Logging & Resolution system
                      </a>
                    </div>
                  </div>


                  <div className='content-block'>

                    <div className='h-4 card-content-headers mb-2'>
                    Engagement Surveys
                    </div>

                    <p>Engagement surveys assist in identifying quick wins that demonstrate a commitment to improvement. Employee suggestions contribute to fostering a positive workplace culture that is relevant and applicable, making them more likely to receive support.</p>
                    <div className='link-container'>
                      <a href=''>
                      Click here to access the Engagement Surveys
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default Culture;
