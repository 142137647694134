import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Link = (props) => {
    const { path, icon, label, sublinks } = props;
    const [show, setShow] = useState(false);
    return (
        <>
            <li className="nav-item">
                {sublinks && sublinks.length > 0 ? (
                    <>
                        <a className="nav-link main-link disable-highlight" onClick={() => setShow(!show)}>

                            <span className="icon-shape icon shadow rounded-4">
                                <i className={icon + " mx-auto p-3 text-dark"}></i>
                            </span>
                            <span className='ms-2 link-label text-dark d-flex w-100'>{label}  {sublinks.length > 0 && <i className={`${show ? " bx bx-chevron-up" : "bx bx-chevron-down"} ms-auto text-dark font-weight-600`}></i>}</span>
                        </a>
                    </>
                ) : (
                    <>
                        <NavLink className="nav-link main-link" to={path} >

                            <span className="icon-shape icon shadow rounded-4">
                                <i className={icon + " mx-auto p-3 text-dark"}></i>
                            </span>
                            <span className='ms-2 link-label text-dark d-flex w-100'>{label}  </span>
                        </NavLink>
                    </>
                )}
            </li>
            {show && sublinks.map((item, key) => (
                <>
                    {item.openNewTab ? <NavLink className="nav-link sublink-container " target="_blank" to={item.url}>
                        <span className="sidenav-mini-icon pb-1">
                            <i class="fas fa-circle "></i>
                        </span>
                        <span className="sidenav-normal "> {item.label} </span>
                    </NavLink> :
                        <NavLink className="nav-link sublink-container " to={item.url}>
                            <span className="sidenav-mini-icon pb-1">
                                <i class="fas fa-circle "></i>
                            </span>
                            <span className="sidenav-normal "> {item.label} </span>
                        </NavLink>
                    }
                </>
            ))}
            <br />
        </>
    )
}
export default Link;
