import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';

const Dashboard = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {

      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header label={<p className='py-0 my-0'>2023 Review Cycle</p>} />
      <main id="main" className="main">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='h-4 card-content-headers mb-2'>
                    Welcome to the Performance Management system.
                  </div>

                  <div className='card-description mb-4'>
                    <p>
                      Performance Management is a framework by which managers and employees work together to plan, monitor, and review an employee’s work objectives and overall contribution to the organisation.
                    </p>
                    <p className='mb-2'>Below is a high-level over of the review process</p>
                  </div>
                 
                  <div className='row'>
                    <div className='col-lg-10 col-md-10 col-sm-8  mx-auto'>
                      <img  className='fluid-image' src='assets/images/perf2.png' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='card-description mb-5'>
                    <p>
                    The rating scale provides a consistent measure of performance across the organisation as depicted in the diagram below:
                    </p>
                  </div>
               
                  <div className='row'>
                    <div className='col-lg-7 col-md-10 col-sm-8  mx-auto'>
                      <img  className='fluid-image' src='assets/images/perf1.png' />
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>

       
      </main>

    </>
  )
}
export default Dashboard;
