import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';


const PerformanceContract = () => {

const navigation = useNavigate();

  return (
    <>
      <Header label={<p className='py-0 my-0'>2023 Review Cycle</p>} />
      <main id="main" className="main">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='d-flex'>
                    <span className='field-label-icon pt-1'>
                      <i class="fa-solid fa-book fa-xl text-secondary"></i>
                    </span>
                    <div className='field-label flex-grow-1 px-4 font-weight-600 text-primary py-1'>My Performance Contract</div>
                  </div>

                  <div className='mx-5 mt-2 d-flex' onClick={()=>{navigation("/performance-contract/1")}}>
                    <i class="fa-solid fa-star fa-xl mt-3 text-orange"></i>
                    <span className=' mt-1 mx-2'>Status:<span className='font-weight-600 text-primary ms-2'>Capture Progress</span></span>
                  </div>
                  <div className='ms-3 mt-3'>
                    <div className='field-label flex-grow-1 px-4 font-weight-600 text-primary py-1 ms-4'>My Direct Report/s</div>
                  </div>
                  <div className='ms-5 mt-3'>
                    <div className='d-flex'>
                      <span className='field-label-icon pt-1'>
                        <i class="fa-solid fa-book fa-xl text-secondary"></i>
                      </span>
                      <div className='field-label flex-grow-1 px-4 font-weight-600 text-primary py-1'>SAM SMITH</div>
                      <button className='btn btn-secondary btn-sm ms-4 rounded-3'>
                        Send Reminder
                      </button>
                    </div>
                    <div className='mx-5 mt-2 d-flex'>
                      <span className=' mt-1 mx-2'>Status:<span className='font-weight-600 text-primary ms-2'>Set-up Performance Contract</span></span>
                    </div>
                  </div>
                  <div className='ms-5 mt-3'>
                    <div className='d-flex'>
                      <span className='field-label-icon pt-1'>
                        <i class="fa-solid fa-book fa-xl text-secondary"></i>
                      </span>
                      <div className='field-label flex-grow-1 px-4 font-weight-600 text-primary py-1'>MAXWELL MPHIOE</div>
                      <button className='btn btn-secondary btn-sm ms-4 rounded-3 d-hidden'>
                        Send Reminder
                      </button>
                    </div>
                    <div className='mx-5 mt-2 d-flex pointer' onClick={()=>{navigation("/performance-contract/self/1")}}>
                      <span className=' mt-1 mx-2'>Status:<span className='font-weight-600 text-primary ms-2'>Self-Assessment</span></span>
                    </div>
                  </div>

                  <div className='ms-5 mt-3'>
                    <div className='d-flex'>
                      <span className='field-label-icon pt-1'>
                        <i class="fa-solid fa-book fa-xl text-secondary"></i>
                      </span>
                      <div className='field-label flex-grow-1 px-4 font-weight-600 text-primary py-1'>SAM SMITH</div>
                      <button className='btn btn-secondary btn-sm ms-4 rounded-3'>
                        Send Reminder
                      </button>
                    </div>
                    <div className='mx-5 mt-2 d-flex'>
                      <span className=' mt-1 mx-2'>Status:<span className='font-weight-600 text-primary ms-2'>Set-up Performance Contract</span></span>
                    </div>
                  </div>

                  <div className='ms-5 mt-3'>
                    <div className='d-flex'>
                      <span className='field-label-icon pt-1'>
                        <i class="fa-solid fa-book fa-xl text-secondary"></i>
                      </span>
                      <div className='field-label flex-grow-1 px-4 font-weight-600 text-primary py-1'>SAM SMITH</div>
                      <button className='btn btn-primary btn-sm ms-4 rounded-3 d-hidden'>
                        Send Reminder
                      </button>
                    </div>
                    <div className='mx-5 mt-2 d-flex'>
                      <i class="fa-solid fa-star fa-xl mt-3 text-orange"></i>
                      <span className=' mt-1 mx-2'>Status:<span className='font-weight-600 text-primary ms-2'> Line Manager Sign-off</span></span>
                    </div>
                  </div>
                  <div className='broken-lines mt-4'></div>
                  <div className='mx-3 mt-2 d-flex'>
                      <i class="fa-solid fa-star fa-xl mt-3 text-orange"></i>
                      <span className='mt-1 mx-2 text-primary'>Your Action Is Required</span>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
export default PerformanceContract;
