import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);


const ProgressDashboard = () => {

  const chartProps5 = {
    type: 'column',
    title: '',
    categories: ["Setup Performance Contract", "Line Manager Sign-off", "Self-Evaluation", "Line Manager Evalution", "Moderation"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        name: 'Not Started',
        data: [8.7, 9.3, 18.8, 52.4, 96.1],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#4D91B6',
      },
      {
        name: 'In Progress',
        data: [91.3, 90.7, 81.2, 47.6, 3.9],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
      }
    ]
  };



  const getOptions = (props) => ({
    chart: {
      type: props.type,
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: props.colorByPoint
      },
      series: {
        borderRadius: '25%'
      }
    },
    colors: props.colors,
    title: {
      text: props.title
    },
    credits: {
      enabled: props.creditsEnabled
    },
    legend: {
      enabled: props.legendEnabled
    },
    yAxis: {
      title: {
        text: 'Percentages(%)',
      },
    },
    xAxis: {
      categories: props.categories,
      labels: {
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'justify',
        },
        tickWidth: 0,
      },
    },
    series: props.series,
  });

  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"People Management/Culture"} />
      <main id="main" className="main">
        <div className='d-flex flex-column'>
          <div>
            <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
            <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
          </div>
          <div>
            <TempFilters />
          </div>
        </div>
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card mt-3'>
                <div className='card-body'>
                  <h5  className='ps-1 fs-4'>Status & Progress</h5>
                  <br />
                  <div className={"scroll-x"} >
                    <table class="table  custom-table" style={{ minWidth: "500px" }}>
                      <thead>
                        <tr className='text-center'>
                          <th scope="col" className='text-start ps-3'>Phase</th>
                          <th scope="col">Total In Phase</th>
                          <th scope="col"> Not Started</th>
                          <th scope="col">Total In progress</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className='text-start ps-3' >Setup Performance Contract</td>
                          <td>596</td>
                          <td>52</td>
                          <td>544</td>
                        </tr>

                        <tr>
                          <td className='text-start ps-3' >Line Manager Sign-off</td>
                          <td>296</td>
                          <td>22</td>
                          <td>214</td>
                        </tr>
                        <tr>
                          <td className='text-start ps-3' >Self-Evaluation</td>
                          <td>2 765</td>
                          <td>519</td>
                          <td>2 246</td>
                        </tr>
                        <tr>
                          <td className='text-start ps-3' >Line Manager Evalution</td>
                          <td>168</td>
                          <td>88</td>
                          <td>80</td>
                        </tr>

                        <tr>
                          <td className='text-start ps-3' >Moderation</td>
                          <td>231</td>
                          <td>222</td>
                          <td>9</td>
                        </tr>
                        <tr className="font-weight-600">
                          <td className="text-end pd-4" >TOTAL</td>
                          <td>3 996</td>
                          <td>903</td>
                          <td>3 093</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                </div>

              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
              <h5 className='fs-4 ms-1'>Progress Distribution</h5>
                  <br />
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps5)} />
                </div>
              </div>
            </div>
          </div>

        </section>
      </main>
    </>
  )
}
export default ProgressDashboard;
