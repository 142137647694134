import axios from 'axios';
import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Header = ({ icon, label }) => {
  const location = useLocation();
  const toggle = () => {
    var sidebar = document.getElementById("sidebar");
    var main = document.getElementsByClassName("main");
    if (document.body.contains(sidebar) && main && sidebar.classList.contains("collapse-nav")) {
      sidebar.classList.remove("collapse-nav");

      for (var i = 0; i < main.length; i++) {
        main[i].classList.remove("collapse-nav")
      }
    } else {
      sidebar.classList.add("collapse-nav");
      for (var i = 0; i < main.length; i++) {
        main[i].classList.add("collapse-nav")
      }
    }
  };


  const processLabel = (label) => {
    if (!(typeof label == 'string')) {
      return label;
    }
    const data = label.split("/");

    if (data.length == 2) {
      return <span> {data[0]}<span className='font-weight-600'> / {data[1]}</span> </span>
    }
    return label;
  }

  return (
    <>
      <main id="main" className="main">
        <div class="page-header border-radius-xl mt-2 bg-primary d-flex" >
        <i class="fa-solid fa-bars mb-5 text-light ms-3 fa-xl" onClick={()=>{toggle()}}></i>

          <h4 className='ms-2 mb-5'>Performance Management</h4>
          <span className='ms-auto me-4 mb-5'>
            Welcome,  Lerato
          </span>
        </div>
        <div class="card card-body blur shadow-blur mx-4 mt-n6 overflow-hidden  rounded-3">
          <div>
            {icon && <i class={icon}></i>}
            <span className='ms-2'>
              {processLabel(label)}
            </span>
          </div>
        </div>
      </main>
      
    </>
  )
}
export default Header;
