
import SignIn from './pages/SignIn';

import {BrowserRouter, MemoryRouter, Route, Routes} from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import { Redirect } from './components/Redirect';
import { baseUrl, chatbotData } from './components/constants';
import axios from 'axios';
import Dashboard from './pages/Dashboard';
import Chatbot from './components/cf-react-chatbot/Chatbot';
import { useState } from 'react';
import Culture from './pages/Culture/Culture';
import Engage from './pages/Engage/Engage';
import Develop from './pages/Develop/Develop';
import Grow from './pages/Grow/Grow';
import Reward from './pages/Reward/Reward';
import ReviewCycle from './pages/ReviewCycle/ReviewCycle';
import PerformanceManagement from './pages/PerformanceContract/PerformanceContract';
import PerformanceContract from './pages/PerformanceContract/PerformanceContract';
import PerformanceDashboard from './pages/PerformanceDistribution/PerformanceDistribution';
import PerformanceDistribution from './pages/PerformanceDistribution/PerformanceDistribution';
import ProgressDashboard from './pages/ProgressDashboard/ProgressDashboard';
import PerformanceContractForm from './pages/PerformanceContract/PerformanceContractForm';
import HelpSupport from "./pages/HelpSupport/HelpSupport";
import IssueLogForm from './pages/IssueLog/IssueLogForm';
import IssueLogs from './pages/IssueLog/IssueLog';
import PerformanceContractFormIndividual from "./pages/PerformanceContract/PerformanceContractFormIndividual";
import PerformanceContractPrior from "./pages/PerformanceContract/PerformanceContractPrior";


const App = () => {
  const [showChatbot, setShowChatbot] = useState(false);
  const [conversation,setConversation] = useState([]);
  const [question,setQuestion]= useState();
  const token = localStorage.getItem("token");
  axios.defaults.baseURL = "http://localhost:8000/api/";
  axios.interceptors.request.use(
    async (config) => {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.credentials = 'include'; // Set the "credentials" option to "include"
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(async (response) => {
    return response;
  }, async function (error) {
    const originalRequest = error.config;
    // console.log(originalRequest)
    if (error.response.status === 401) { localStorage.removeItem('token'); window.location.reload(true); }
    if (error.response.status === 408) { localStorage.removeItem('token'); window.location.reload(true); }
    return Promise.reject(error);
  });

const getConversation = ()=>{
  axios.get("getConversations").then((res)=>{
    setConversation(res.data.data)
  }).catch(()=>{

  })
}
const createQuestion = ()=>{
  axios.post("createQuestion",{question}).then((res)=>{
    getConversation();
    setQuestion("");
  }).catch(()=>{

  })
}


  return (
    <>
     
      {
 <button className="chatbot-toggler shadow ">
<span className="material-symbols-rounded">
  <div className="bot-icon-container" onClick={() => { setShowChatbot((val) => { return !val }) }}>
    {showChatbot && <i class="fa-solid fa-xmark text-light"></i>}
    {!showChatbot && <i class="fa-solid fa-message text-light"></i>}
  </div>
  {showChatbot && <div className='position-absolute chatbot-package shadow rounded-4'>
    <Chatbot
      data={chatbotData}
      getConversation={getConversation }
      conversation = {conversation}
      // onClose={()=>{ toggleBot(false)}}
      onChange = {(value)=>{ setQuestion(value)}}
      onClose={() => { }}
      onSubmit={() => { createQuestion() }}
    />
  </div>
  }
</span>
</button>
      }
      <MemoryRouter>
        {<Sidebar />}
        <Routes >
          <Route exact path="/" element={<ProtectedRoutes element={<Dashboard />} />} />
          <Route path="/culture" element={<ProtectedRoutes element={< Culture />} />} />
          <Route path="/engage" element={<ProtectedRoutes element={< Engage />} />} />
          <Route path="/learn" element={<ProtectedRoutes element={< Develop />} />} />
          <Route path="/grow" element={<ProtectedRoutes element={< Grow />} />} />
          <Route path="/reward" element={<ProtectedRoutes element={< Reward />} />} />

          {/* <Route path="/2022-review-cycle" element={<ProtectedRoutes element={< ReviewCycle />} />} /> */}
          <Route path="/performance-contract" element={<ProtectedRoutes element={< PerformanceContract />} />} />
          <Route path="/performance-contract/:id" element={<ProtectedRoutes element={< PerformanceContractForm />} />} />
          <Route path="/performance-contract/self/:id" element={<ProtectedRoutes element={< PerformanceContractFormIndividual />} />} />
          <Route path="/2022-review-cycle" element={<ProtectedRoutes element={< PerformanceContractPrior />} />} />
          <Route path="/performance-dashboard" element={<ProtectedRoutes element={< PerformanceDashboard />} />} />
          <Route path="/performance-distribution" element={<ProtectedRoutes element={< PerformanceDistribution />} />} />
          <Route path="/progress-dashboard" element={<ProtectedRoutes element={< ProgressDashboard />} />} />
          <Route path="/help-support" element={<ProtectedRoutes element={<HelpSupport/>} />} />
          <Route path="/issue-logs" element={<ProtectedRoutes element={<IssueLogs/>} />} />
          <Route path="/issue-logs/add" element={<ProtectedRoutes element={<IssueLogForm/>} />} /> 
          <Route path="/issue-logs/:id" element={<ProtectedRoutes element={<IssueLogForm/>} />} /> 


        </Routes>
        <Routes>
          <Route path="/login" element={<Redirect element={< Dashboard />} />} />
        </Routes>
      </MemoryRouter>
    </>
  );
}

export default App;
