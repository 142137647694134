import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const values = ["1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5",];
const PerformanceContractForm = () => {
  const [showRow1, setShowRow1] = useState(false);
  const [showRow2, setShowRow2] = useState(false);
  const [showRow3, setShowRow3] = useState(false);
  const [overallSelf, setOverallSelf] = useState(0);
  const [overallLineManager, setOverallLineManager] = useState(0);
  const [overallModerator, setOverallModerator] = useState(0);
  const [overallModeratorComment, setOverallModeratorComment] = useState('');
  const [kpaWeightTotal, setKpaWeightTotal] = useState(100);
  const [kpaSelfSectionA, setKpaSelfSectionA] = useState(3.5);
  const [kpaSelfSectionB, setKpaSelfSectionB] = useState(0);
  const [kpaLineManager, setKpaLineManager] = useState(3.5);
  const [selfKpa1, setSelfKpa1] = useState(3.5);
  const [selfKpa2, setSelfKpa2] = useState(3.5);
  const [selfKpa3, setSelfKpa3] = useState(3.5);
  const [weightSelfKPA1, setWeightSelfKPA1] = useState(40);
  const [weightSelfKPA2, setWeightSelfKPA2] = useState(20);
  const [weightSelfKPA3, setWeightSelfKPA3] = useState(40);

  const [weightSelfKPB1, setWeightSelfKPB1] = useState(50);
  const [weightSelfKPB2, setWeightSelfKPB2] = useState(30);
  const [weightSelfKPB3, setWeightSelfKPB3] = useState(20);
  const [sectionATotWeight, setSectionATotWeight] = useState(40);

  const [selfKpaB1, setSelfKpaB1] = useState(0);
  const [selfKpaB2, setSelfKpaB2] = useState(0);
  const [selfKpaB3, setSelfKpaB3] = useState(0);

  const handleMyKpa = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let total = 0;

    if (name === 'selfKpa1'){
      total = (weightSelfKPA1/100) * value + (weightSelfKPA2/100) * selfKpa2 + (weightSelfKPA3/100) * selfKpa3;
      setSelfKpa1(value)
      setKpaSelfSectionA(customRound(total))

    }else if (name === 'selfKpa2'){
      total = (weightSelfKPA2/100) * value + (weightSelfKPA1/100) * selfKpa1 + (weightSelfKPA3/100) * selfKpa3;
      setSelfKpa2(value)
      setKpaSelfSectionA(customRound(total))

    }else if (name === 'selfKpa3'){
      total = (weightSelfKPA3/100) * value + (weightSelfKPA1/100) * selfKpa1 + (weightSelfKPA2/100) * selfKpa2;
      setSelfKpa3(value)
      setKpaSelfSectionA(customRound(total))

    }


  }

  const handleMyKpaSectionB = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let total = 0;

    if (name === 'selfKpaB1'){
      total = (weightSelfKPB1/100) * value + (weightSelfKPB2/100) * selfKpaB2 + (weightSelfKPB3/100) * selfKpaB3;
      setSelfKpaB1(value)
      setKpaSelfSectionB(customRound(total))

    }else if (name === 'selfKpaB2'){
      total = (weightSelfKPB2/100) * value + (weightSelfKPB1/100) * selfKpaB1 + (weightSelfKPB3/100) * selfKpaB3;
      setSelfKpaB2(value)
      setKpaSelfSectionB(customRound(total))

    }else if (name === 'selfKpaB3'){
      total = (weightSelfKPA3/100) * value + (weightSelfKPA1/100) * selfKpaB1 + (weightSelfKPA2/100) * selfKpaB2;
      setSelfKpaB3(value)
      setKpaSelfSectionB(customRound(total))

    }


  }

  useEffect(() => {
    let total = (kpaSelfSectionA * 80/100) + (kpaSelfSectionB * 20/100);
    total = customRound(total);
    setOverallSelf(total);
  }, [kpaSelfSectionA, kpaSelfSectionB])

  function customRound(number) {
    const decimalPart = number - Math.floor(number);

    if (decimalPart === 0.5) {
      return number; // Don't round if the decimal part is exactly 0.5
    } else if (decimalPart < 0.5) {
      return Math.floor(number); // Round down for decimal parts less than 0.5
    } else {
      return Math.ceil(number); // Round up for decimal parts greater than 0.5
    }
  }


  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Current Revie Cycle / My Performance Contract"} />
      <main id="main" className="main">

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>

              <div className='card-body'>

                <div className='section'>
                  <div className='section-header bg-tertiary p-2 text-light font-weight-600'>
                    Performance Contract
                  </div>
                  <div className='section-body p-3'>
                    <div className='row'>
                      <div className='col-2 mb-2'>
                        Cycle under review:
                      </div>
                      <div className='col-10 mb-2'>
                        2023 Cycle Review
                      </div>

                      <div className='col-2 mb-2'>
                        Employee Name:
                      </div>
                      <div className='col-10 mb-2'>
                       Maxwell Mphioe
                      </div>
                      <div className='col-2 mb-2'>
                        Position Title:
                      </div>
                      <div className='col-10 mb-2'>
                        Software Developer: Strategic Projects
                      </div>

                      <div className='col-2 mb-2'>
                        Line Manager:
                      </div>
                      <div className='col-10 mb-2'>
                        Joe Slovo
                      </div>
                    </div>
                  </div>
                </div>

                <div className='section mb-4'>
                  <div className='section-header bg-tertiary p-2 text-light font-weight-600'>
                    Overall Individual Score for Current Cycle
                  </div>
                  <table className="table over-all-score border border-top-0">
                    <tr>
                      <td rowSpan={2} className="bg-secondary section-title">
                       <span className="text-white"> Overall Score</span>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-2">Self: </span>
                          <input type="text" value={overallSelf} name="overallSelf" className="perf-input" disabled/>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-2">Line Manager: </span>
                          <input type="text" value={overallLineManager} name="overallLineManager" className="perf-input" disabled/>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-2">Final / Moderator: </span>
                          <input type="text" value={overallModerator} name="overallModerator" className="perf-input" disabled/>
                        </div>
                      </td>

                      <td >
                        <div className="d-flex align-items-center">
                          <span className="me-2">Moderator Comments: </span>
                          <textarea name="overall-moderator-comment" disabled className='form-control' rows={2} >{overallModeratorComment}</textarea>
                        </div>
                      </td>

                    </tr>
                  </table>
                </div>

                <div className='section my-4'>
                  <div className=' ps-2 section-header bg-tertiary p-2 text-light font-weight-600 ps-3'>
                    Section A: Key Performance Areas
                  </div>

                  <table className="table kpa-total border border-top-0">
                    <tr>
                      <td rowSpan={2} className="bg-secondary section-title">
                        <span className="text-white"> KPA Score (80%)</span>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-2">Weight Total: </span>
                          <input type="text" value={kpaWeightTotal} name="kpaWeightTotal" className="perf-input" disabled/>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-2">Self: </span>
                          <input type="text" value={kpaSelfSectionA} name="kpaSelfSectionA" className="perf-input" disabled/>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-2">Line Manager: </span>
                          <input type="text" value={kpaLineManager} name="kpaLineManager" className="perf-input" disabled/>
                        </div>
                      </td>


                    </tr>
                  </table>

                </div>

                <div className='section '>

                  <div className='section-body'>
                    <table className='table kpa-total table-bordered'>
                      <tbody>

                        <tr>
                          <td className='bg-tertiary text-light'>KPA</td>
                          <td colSpan={2} className='bg-secondary kpa-description'>
                            <div className='d-flex flex-column'>
                              <div className='text-light'>
                                Risk Mitigation
                              </div>
                              <div className='text-end font-weight-600'> Customised KPA</div>
                            </div>
                          </td>
                          <td className='pt-2 ms-5 '>
                            <div className="d-flex flex-column">
                              <span className='ms-2'>Weight:</span>
                              <input type='number' disabled className='number-input border rounded ms-2' value={weightSelfKPA1} />
                            </div>
                          </td>

                          <td className=''>
                            <div className="d-flex justify-content-between align-items-center">

                              <div className="d-flex align-items-center">
                                <span className="me-2">Self: </span>
                                <select name='selfKpa1' value={selfKpa1} onChange={(e)=>{handleMyKpa(e)}}>
                                  <option></option>
                                  {values.map((item) => (
                                      <option value={item}>{item}</option>
                                  ))}
                                </select>
                              </div>

                              <div className="d-flex align-items-center">
                                <span className="me-2">Line Manager: </span>
                                <select name='LineManagerKpa1' disabled value={0} >
                                  <option value={0}>0</option>
                                </select>
                              </div>

                              <div className='me-2'>
                                <button
                                    className='btn btn-secondary btn-sm py-1 my-1 mt-2  text-nowrap mb-3'
                                    onClick={()=> {setShowRow1(!showRow1)}}
                                >{showRow1? 'Hide': 'Show'} KPIs</button>
                              </div>

                            </div>
                          </td>

                        </tr>

                        {showRow1 && (
                            <>
                              <tr className="">
                                <td className='title-col'></td>
                                <td className='bg-tertiary text-light title-col'>PI</td>
                                <td className='kpi-description-td'>
                                  <div className='d-flex flex-column justify-content-between bg-secondary p-1 kpi-description'>
                                    <div className='text-light'>
                                      Mentorship or Pair Programming
                                      Sessions
                                    </div>
                                    <div className='text-end font-weight-600 font-style-italic mt-3'>Customised KPA</div>
                                  </div>

                                  <div className="mt-3">
                                    <div className=''>Resource Requirements & Enabling Conditions:</div>
                                    <textarea disabled className='form-control'>
                                     Technology and Tools, Budget, and Leadership Support.
                                    </textarea>
                                  </div>

                                </td>
                                <td colSpan={2} className="">

                                  <table className='table '>
                                    <tr>
                                      <td>
                                        <div>
                                          <div className=''>Annual Target:</div>
                                          <textarea disabled className='form-control'>
                                      Aim to conduct a minimum of two
mentorship or pair programming
sessions per month to facilitate knowledge
transfer and skill development within
the team.
                                    </textarea>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <div className='text-wrap'>Measurement:</div>
                                          <textarea disabled className='form-control'>
                                      Number of mentorship or pair sessions conducted with team members.
                                    </textarea>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>

                                  <div>
                                    <div className='text-wrap'>Progress / Challenges / Corrective Measures:</div>
                                    <textarea className='form-control'></textarea>
                                  </div>

                                </td>
                              </tr>

                              <tr className="">
                                <td className='title-col'></td>
                                <td className='bg-tertiary text-light title-col'>PI</td>
                                <td className='kpi-description-td'>
                                  <div className='d-flex flex-column justify-content-between bg-secondary p-1 kpi-description'>
                                    <div className='text-light'>
                                      Code Review Participation
                                    </div>
                                    <div className='text-end font-weight-600 font-style-italic mt-3'>Customised KPA</div>
                                  </div>

                                  <div className="mt-3">
                                    <div className=''>Resource Requirements & Enabling Conditions:</div>
                                    <textarea disabled className='form-control'>
                                     Technology and Tools, Budget, and Leadership Support.
                                    </textarea>
                                  </div>

                                </td>
                                <td colSpan={2} className="">
                                  <table className='table '>
                                    <tr>
                                      <td>
                                        <div>
                                          <div className=''>Annual Target:</div>
                                          <textarea disabled className='form-control'>
                                      Aim to actively participate in at least 90%
of code reviews to provide valuable
input and maintain code quality.
                                    </textarea>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <div className='text-wrap'>Measurement:</div>
                                          <textarea disabled className='form-control'>
                                     Percentage of code reviews participated
in within the team.
                                    </textarea>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2">
                                        <div>
                                          <div className='text-wrap'>Progress / Challenges / Corrective Measures:</div>
                                          <textarea className='form-control'></textarea>
                                        </div>
                                      </td>

                                    </tr>


                                  </table>
                                </td>
                              </tr>
                            </>
                        )}

                        <tr>
                          <td className='bg-tertiary text-light'>KPA</td>
                          <td colSpan={2} className='bg-secondary kpa-description'>
                            <div className='d-flex flex-column'>
                              <div className='text-light'>
                                Bug Resolution Time - Average time
                                taken to resolve and close reported
                                software bugs.
                              </div>
                              <div className='text-end font-weight-600'> Customised KPA</div>
                            </div>
                          </td>
                          <td className='pt-2 ms-5 '>
                            <div className="d-flex flex-column">
                              <span className='ms-2'>Weight:</span>
                              <input type='number' disabled className='number-input border rounded ms-2' value={weightSelfKPA2}  />
                            </div>
                          </td>

                          <td className=''>
                            <div className="d-flex justify-content-between align-items-center">

                              <div className="d-flex align-items-center">
                                <span className="me-2">Self: </span>
                                <select name='selfKpa2' value={selfKpa2} onChange={(e)=>{handleMyKpa(e)}}>
                                  <option></option>
                                  {values.map((item) => (
                                      <option value={item}>{item}</option>
                                  ))}
                                </select>
                              </div>

                              <div className="d-flex align-items-center">
                                <span className="me-2">Line Manager: </span>
                                <select name='LineManagerKpa1' disabled value={0}>
                                  <option value={0}>0</option>
                                </select>
                              </div>

                              <div className='me-2'>
                                <button
                                    className='btn btn-secondary btn-sm py-1 my-1 mt-2  text-nowrap mb-3'
                                    onClick={()=> {setShowRow2(!showRow2)}}
                                >{showRow2? 'Hide': 'Show'} KPIs</button>
                              </div>

                            </div>
                          </td>

                        </tr>
                        {showRow2 && (
                            <>
                              <tr className="">
                                <td className='title-col'></td>
                                <td className='bg-tertiary text-light title-col'>PI</td>
                                <td className='kpi-description-td'>
                                  <div className='d-flex flex-column justify-content-between bg-secondary p-1 kpi-description'>
                                    <div className='text-light'>
                                      Track the effectiveness of the risk
                                      management strategy by monitoring
                                      the number and impact of risks
                                      identified and mitigated during the
                                      project.
                                    </div>
                                    <div className='text-end font-weight-600 font-style-italic mt-3'>Customised KPA</div>
                                  </div>

                                  <div className="mt-3">
                                    <div className=''>Annual Target:</div>
                                    <textarea disabled className='form-control'>
                                      Achieve a target of mitigating 90% of
                                      identified risks annually.
                                    </textarea>
                                  </div>

                                </td>
                                <td colSpan={2} className="">
                                  <table className='table '>
                                    <tr>
                                      <td>
                                        <div>
                                          <div className=''>Annual Target:</div>
                                          <textarea disabled className='form-control'>
                                      Achieve a target of mitigating 90% of
                                      identified risks annually.
                                    </textarea>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <div className='text-wrap'>Resource Requirements & Enabling Conditions:</div>
                                          <textarea disabled className='form-control'>
                                      Technology and Tools, Budget, and
                                      Leadership Support
                                    </textarea>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2">
                                        <div>
                                          <div className='text-wrap'>Progress / Challenges / Corrective Measures:</div>
                                          <textarea className='form-control'></textarea>
                                        </div>
                                      </td>

                                    </tr>


                                  </table>
                                </td>
                              </tr>
                            </>
                        )}

                        <tr>
                          <td className='bg-tertiary text-light'>KPA</td>
                          <td colSpan={2} className='bg-secondary kpa-description'>
                            <div className='d-flex flex-column'>
                              <div className='text-light'>
                                On-Time Delivery
                              </div>
                              <div className='text-end font-weight-600'> Customised KPA</div>
                            </div>
                          </td>
                          <td className='pt-2 ms-5 '>
                            <div className="d-flex flex-column">
                              <span className='ms-2'>Weight:</span>
                              <input type='number' disabled className='number-input border rounded ms-2' value={weightSelfKPA3} />
                            </div>
                          </td>

                          <td className=''>
                            <div className="d-flex justify-content-between align-items-center">

                              <div className="d-flex align-items-center">
                                <span className="me-2">Self: </span>
                                <select name='selfKpa3' value={selfKpa3} onChange={(e)=>{handleMyKpa(e)}}>
                                  <option></option>
                                  {values.map((item) => (
                                      <option value={item}>{item}</option>
                                  ))}
                                </select>
                              </div>

                              <div className="d-flex align-items-center">
                                <span className="me-2">Line Manager: </span>
                                <select name='LineManagerKpa1' disabled value={0}>
                                  <option value={0}>0</option>
                                </select>
                              </div>

                              <div className='me-2'>
                                <button
                                    className='btn btn-secondary btn-sm py-1 my-1 mt-2  text-nowrap mb-3'
                                    onClick={()=> {setShowRow3(!showRow3)}}
                                >{showRow3? 'Hide': 'Show'} KPIs</button>
                              </div>

                            </div>
                          </td>

                        </tr>
                        {showRow3 && (
                            <>
                              <tr className={`fade-in-element ${showRow3 ? 'fade-in' : ''}`}>
                                <td className='title-col'></td>
                                <td className='bg-tertiary text-light title-col'>PI</td>
                                <td className='kpi-description-td'>
                                  <div className='d-flex flex-column justify-content-between bg-secondary p-1 kpi-description'>
                                    <div className='text-light'>
                                      Track the effectiveness of the risk
                                      management strategy by monitoring
                                      the number and impact of risks
                                      identified and mitigated during the
                                      project.
                                    </div>
                                    <div className='text-end font-weight-600 font-style-italic mt-3'>Customised KPA</div>
                                  </div>

                                  <div className="mt-3">
                                    <div className=''>Annual Target:</div>
                                    <textarea disabled className='form-control'>
                                      Achieve a target of mitigating 90% of
                                      identified risks annually.
                                    </textarea>
                                  </div>

                                </td>
                                <td colSpan={2} className="">
                                  <table className='table '>
                                    <tr>
                                      <td>
                                        <div>
                                          <div className=''>Annual Target:</div>
                                          <textarea disabled className='form-control'>
                                      Achieve a target of mitigating 90% of
                                      identified risks annually.
                                    </textarea>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <div className='text-wrap'>Resource Requirements & Enabling Conditions:</div>
                                          <textarea disabled className='form-control'>
                                      Technology and Tools, Budget, and
                                      Leadership Support
                                    </textarea>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2">
                                        <div>
                                          <div className='text-wrap'>Progress / Challenges / Corrective Measures:</div>
                                          <textarea className='form-control'></textarea>
                                        </div>
                                      </td>

                                    </tr>


                                  </table>
                                </td>
                              </tr>
                            </>
                        )}

                      </tbody>
                    </table>
                  </div>
                </div>

                <div className='section mt-4'>
                  <div className='section-header bg-tertiary p-2 text-light font-weight-600'>
                    Section B: Our Principles & Standards
                  </div>
                  <table className="table kpa-total border border-top-0">
                    <tr>
                      <td rowSpan={2} className="bg-secondary section-title">
                        <span className="text-white"> Generic Averages (20%)</span>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-2">Weight Total: </span>
                          <input type="text" value={100} name="kpaWeightTotal" className="perf-input" disabled/>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-2">Self: </span>
                          <input type="text" value={kpaSelfSectionB} name="kpaSelfSectionB" className="perf-input" disabled/>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-2">Line Manager: </span>
                          <input type="text" value={0} name="kpaLineManagerB" className="perf-input" disabled/>
                        </div>
                      </td>


                    </tr>
                  </table>

                </div>

                <div className='section mt-4'>

                  <div className='section-body'>
                    <table className='table' >
                      <tr>
                        <th >Generic:</th>
                        <th>Guidelines:</th>
                        <th>Weight:</th>
                        <th></th>

                      </tr>
                      <tr>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Integrity
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Always be truthful and honest in actions,
                              communications, and dealings.
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <input type='number' readOnly={true} disabled className='number-input border rounded ms-2' value={weightSelfKPB1} />
                        </td>
                        <td>
                          <div className='d-flex'>

                            <div className='flex-grow-1 d-flex pt-2'>
                              <div className="d-flex align-items-center">
                                <span className="me-2">Self: </span>
                                <select name='selfKpaB1' value={selfKpaB1} onChange={(e)=>{handleMyKpaSectionB(e)}}>
                                  <option></option>
                                  {values.map((item) => (
                                      <option value={item}>{item}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className='flex-grow-1 d-flex pt-2 ms-5'>
                              <div className="d-flex align-items-center">
                                <span className="me-2">Line Manager: </span>
                                <select name='' value={0} disabled>
                                  <option value={0}>0</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Culture
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Collaboration among teams to leverage
                              collective expertise and perspectives.
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <input type='number' disabled className='number-input border rounded ms-2' value={weightSelfKPB2} />
                        </td>
                        <td>
                          <div className='d-flex'>

                            <div className='flex-grow-1 d-flex pt-2'>
                              <div className="d-flex align-items-center">
                                <span className="me-2">Self: </span>
                                <select name='selfKpaB2' value={selfKpaB2} onChange={(e)=>{handleMyKpaSectionB(e)}}>
                                  <option></option>
                                  {values.map((item) => (
                                      <option value={item}>{item}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className='flex-grow-1 d-flex pt-2 ms-5'>
                              <div className="d-flex align-items-center">
                                <span className="me-2">Line Manager: </span>
                                <select name='' value={0} disabled>
                                  <option value={0}>0</option>
                                </select>
                              </div>
                            </div>

                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Problem Solving
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Identifying and addressing the root causes
                              of problems rather than addressing symptoms
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <input type='number' disabled className='number-input border rounded ms-2' value={weightSelfKPB3} />
                        </td>
                        <td>
                          <div className='d-flex'>

                            <div className='flex-grow-1 d-flex pt-2'>
                              <div className="d-flex align-items-center">
                                <span className="me-2">Self: </span>
                                <select name='selfKpaB3' value={selfKpaB3} onChange={(e)=>{handleMyKpaSectionB(e)}}>
                                  <option></option>
                                  {values.map((item) => (
                                      <option value={item}>{item}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className='flex-grow-1 d-flex pt-2 ms-5'>
                              <div className="d-flex align-items-center">
                                <span className="me-2">Line Manager: </span>
                                <select name='' value={0} disabled>
                                  <option value={0}>0</option>
                                </select>
                              </div>
                            </div>

                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className='section mt-4'>
                  <div className='section-header bg-tertiary p-2 text-light font-weight-600'>
                    Section C: Personal Development Plan
                  </div>
                  <div className='section-body'>
                    <table className='table ' >
                      <tr>
                        <th >Competency to Address:</th>
                        <th>Proposed Actions:</th>
                        <th>Responsibility:</th>
                        <th>Progress / Outcome:</th>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Financial Management
                            </textarea>
                          </div>
                        </td>
                        <td> <div>
                          <textarea disabled className='form-control'>
                          Find applicable course on Internal LMS system
                          </textarea>
                        </div></td>
                        <td> <div>
                          <textarea disabled className='form-control'>
                          Finish and pass the course. Apply knowledge accordingly.
                          </textarea>
                        </div>
                        </td>
                        <td>
                          <div>
                            <textarea className='form-control'>
                            </textarea>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Proposal Writing
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                            Find applicable course on Internal LMS system
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                            Finish and pass the course. Improvement in proposal writing.
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea className='form-control'>
                            </textarea>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="d-flex">
                    <input type="radio" name="hello" className="mb-4"/>
                    <div className="ms-2">I have finalised my progress capturing</div>
                  </div>
                  <button className='btn btn-secondary btn-sm py-1 my-1 mt-2  text-nowrap ms-auto me-3'>SAVE</button>
                  
                  <button className='btn btn-tertiary btn-sm py-1 my-1 mt-2  text-nowrap'>SUBMIT</button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </main >
    </>
  )
}
export default PerformanceContractForm;
