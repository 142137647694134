import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';


const PerformanceContractForm = () => {
  const [showRow3, setShowRow3] = useState(false);


  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Current Revie Cycle / Performance Contract"} />
      <main id="main" className="main">

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>

              <div className='card-body'>

                <div className='section'>
                  <div className='section-header bg-tertiary p-2 text-light font-weight-600'>
                    Performance Contract
                  </div>
                  <div className='section-body p-3'>
                    <div className='row'>
                      <div className='col-2 mb-2'>
                        Cycle under review:
                      </div>
                      <div className='col-10 mb-2'>
                        2023 Cycle Review
                      </div>

                      <div className='col-2 mb-2'>
                        Employee Name:
                      </div>
                      <div className='col-10 mb-2'>
                        Lerato Bopape
                      </div>
                      <div className='col-2 mb-2'>
                        Position Title:
                      </div>
                      <div className='col-10 mb-2'>
                        Programme Manager: Strategic Projects
                      </div>

                      <div className='col-2 mb-2'>
                        Line Manager:
                      </div>
                      <div className='col-10 mb-2'>
                        Joe Slovo
                      </div>
                    </div>
                  </div>
                </div>

                <div className='section mb-4'>
                  <div className='section-header bg-tertiary p-2 text-light font-weight-600'>
                    Overall Individual Score for Current Cycyle
                  </div>
                  <div className='section-body border'>
                    <div className='d-flex '>
                      <div className='flex-grow-1 bg-secondary p-2 text-light'>Overall Score</div>
                      <div className='flex-grow-1 d-flex pt-2 p-2'>
                        <span className='mt-1'>self:</span>
                        <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                      </div>


                      <div className='flex-grow-1 d-flex pt-2'>
                        <span className='mt-1'>Line Manager:</span>
                        <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                      </div>

                      <div className='flex-grow-1 d-flex pt-2'>
                        <span className='mt-1'>Final / Moderator:</span>
                        <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />

                      </div>

                      <div className='flex-grow-1 d-flex'>
                        <span className='mt-1'>Moderator Comments:</span>
                        <textarea defaultChecked={0} disabled className='form-control my-2 me-2' rows={1} >

                        </textarea>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='section mb-4'>
                  <div className=' ps-2 section-header bg-tertiary p-2 text-light font-weight-600 ps-3'>
                    Section A: Key Performance Areas
                  </div>
                  <div className='section-body border'>

                    <div className='d-flex'>
                      <div className=' bg-secondary text-light py-3' style={{ width: "130px" }}>KPA Score (80%)</div>
                      <div className='flex-grow-1 d-flex pt-2 mx-2'>
                        <span className='mt-1'>Weight Total:</span>
                        <input type='number' disabled className='number-input border rounded ms-2' defaultValue={100} />
                      </div>

                      <div className='d-flex'>
                        <div className='flex-grow-1 d-flex pt-2'>
                          <span className='mt-1'>Self:</span>
                          <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                        </div>

                        <div className='flex-grow-1 d-flex pt-2 ms-5'>
                          <span className='mt-1'>Line Manager:</span>
                          <input type='number' disabled className='number-input border rounded ms-2 me-2' defaultValue={0} />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='section '>

                  <div className='section-body'>
                    <table className='table table-bordered'>
                      <tbody>
                        <tr>
                          <td className='bg-tertiary text-light'>KPA</td>
                          <td colSpan={2} className='bg-secondary'>
                            <div className='d-flex flex-column'>
                              <div className='text-light'>

                                Quality of Deliverables: Assess the
                                quality of project deliverables
                                and whether they meet predefined
                                standards and specifications.
                              </div>
                              <div className='text-end font-weight-600'> Cascaded as is from Manager</div>
                            </div>
                          </td>
                          <td className='pt-2 ms-5'>
                            <div className='mt-1'>Weight:</div>
                            <input type='number' disabled className='number-input border rounded ms-2' defaultValue={40} />
                          </td>

                          <td className=''>
                            <div className='d-flex'>

                              <div className=''>
                                <div className='d-flex'>
                                  <div className='d-flex'>
                                    <div className='flex-grow-1 d-flex pt-4 ms-5'>
                                      <span className='mt-1'>self:</span>
                                      <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                                    </div>


                                    <div className='flex-grow-1 d-flex pt-4 ms-5'>
                                      <span className='mt-1'>Line Manager:</span>
                                      <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                                    </div>

                                    <div className='flex-grow-1 d-flex ms-3 pt-3'>
                                      <button className='btn btn-secondary btn-sm py-1 my-1 mt-2  text-nowrap mb-3'>Open KPIs</button>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='bg-tertiary text-light'>KPA</td>
                          <td colSpan={2} className='bg-secondary'>
                            <div className='d-flex flex-column'>
                              <div className='text-light'>

                                Quality of Deliverables: Assess the
                                quality of project deliverables and
                                whether they meet predefined
                                standards and specifications.
                              </div>
                              <div className='text-end font-weight-600'> Customised KPA</div>
                            </div>
                          </td>
                          <td className='pt-2 ms-5 '>
                            <div className='mt-1'>Weight:</div>
                            <input type='number' disabled className='number-input border rounded ms-2' defaultValue={20} />
                          </td>

                          <td className=''>
                            <div className='d-flex'>

                              <div className=''>
                                <div className='d-flex'>
                                  <div className='d-flex'>
                                    <div className='flex-grow-1 d-flex pt-4 ms-5'>
                                      <span className='mt-1'>self:</span>
                                      <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                                    </div>


                                    <div className='flex-grow-1 d-flex pt-4 ms-5'>
                                      <span className='mt-1'>Line Manager:</span>
                                      <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                                    </div>

                                    <div className='flex-grow-1 d-flex ms-3 pt-3'>
                                      <button className='btn btn-secondary btn-sm py-1 my-1 mt-2  text-nowrap mb-3'>Open KPIs</button>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className='bg-tertiary text-light'>KPA</td>
                          <td colSpan={2} className='bg-secondary'>
                            <div className='d-flex flex-column'>
                              <div className='text-light'>
                                Risk Mitigation
                              </div>
                              <div className='text-end font-weight-600'> Customised KPA</div>
                            </div>
                          </td>
                          <td className='pt-2 ms-5 '>
                            <div className='mt-1'>Weight:</div>
                            <input type='number' disabled className='number-input border rounded ms-2' defaultValue={40} />
                          </td>

                          <td className=''>
                            <div className='d-flex'>

                              <div className=''>
                                <div className='d-flex'>
                                  <div className='d-flex'>
                                    <div className='flex-grow-1 d-flex pt-4 ms-5'>
                                      <span className='mt-1'>self:</span>
                                      <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                                    </div>


                                    <div className='flex-grow-1 d-flex pt-4 ms-5'>
                                      <span className='mt-1'>Line Manager:</span>
                                      <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                                    </div>

                                    <div className='flex-grow-1 d-flex ms-3 pt-3'>
                                      <button
                                          className='btn btn-secondary btn-sm py-1 my-1 mt-2  text-nowrap mb-3'
                                          onClick={()=> {setShowRow3(!showRow3)}}
                                      >{showRow3? 'Hide': 'Show'} KPIs</button>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>

                        {showRow3 && (
                            <>
                              <tr className={`fade-in-element ${showRow3 ? 'fade-in' : ''}`}>
                                <td className='title-col'></td>
                                <td className='bg-tertiary text-light title-col'>PI</td>
                                <td className='bg-secondary'>
                                  <div className='d-flex flex-column '>
                                    <div className='text-light'>
                                      Track the effectiveness of the risk
                                      management strategy by monitoring
                                      the number and impact of risks
                                      identified and mitigated during the
                                      project.
                                    </div>
                                    <div className='text-end font-weight-600 font-style-italic'>Customised KPA</div>
                                  </div>
                                </td>
                                <td colSpan={2} className={`fade-in-element ${showRow3 ? 'fade-in' : ''}`}>
                                  <table className='table '>
                                    <tr>
                                      <td>
                                        <div>
                                          <div className=''>Annual Target:</div>
                                          <textarea disabled className='form-control'>
                                      Achieve a target of mitigating 90% of
                                      identified risks annually.
                                    </textarea>
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          <div className='text-wrap'>Resource Requirements & Enabling Conditions:</div>
                                          <textarea disabled className='form-control'>
                                      Technology and Tools, Budget, and
                                      Leadership Support
                                    </textarea>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2">
                                        <div>
                                          <div className='text-wrap'>Progress / Challenges / Corrective Measures:</div>
                                          <textarea className='form-control'></textarea>
                                        </div>
                                      </td>

                                    </tr>


                                  </table>
                                </td>
                              </tr>
                            </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className='section mt-4'>
                  <div className='section-header bg-tertiary p-2 text-light font-weight-600'>
                    Section B: Our Principles & Standards
                  </div>
                  <div className='section-body border'>

                    <div className='d-flex'>
                      <div className=' bg-secondary text-light p-2 py-3' style={{ width: "200px" }}>Generic Averages (20%)</div>
                      <div className='flex-grow-1 d-flex pt-2 mx-2'>
                        <span className='mt-1'>Weight Total:</span>
                        <input type='number' disabled className='number-input border rounded ms-2' defaultValue={100} />
                      </div>

                      <div className='d-flex'>
                        <div className='flex-grow-1 d-flex pt-2'>
                          <span className='mt-1'>Self:</span>
                          <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                        </div>



                        <div className='flex-grow-1 d-flex pt-2 ms-5'>
                          <span className='mt-1'>Line Manager:</span>
                          <input type='number' disabled className='number-input border rounded ms-2 me-2' defaultValue={0} />
                        </div>


                      </div>
                    </div>

                  </div>
                </div>

                <div className='section mt-4'>

                  <div className='section-body'>
                    <table className='table' >
                      <tr>
                        <th >Generic:</th>
                        <th>Guidelines:</th>
                        <th>Weight:</th>
                        <th></th>

                      </tr>
                      <tr>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Integrity
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Always be truthful and honest in actions,
                              communications, and dealings.
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                        </td>
                        <td>
                          <div className='d-flex'>
                            <div className='flex-grow-1 d-flex pt-2'>
                              <span className='mt-1'>Self:</span>
                              <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                            </div>
                            <div className='flex-grow-1 d-flex pt-2 ms-5'>
                              <span className='mt-1'>Line Manager:</span>
                              <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Culture
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Collaboration among teams to leverage
                              collective expertise and perspectives.
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                        </td>
                        <td>
                          <div className='d-flex'>
                            <div className='flex-grow-1 d-flex pt-2'>
                              <span className='mt-1'>Self:</span>
                              <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                            </div>
                            <div className='flex-grow-1 d-flex pt-2 ms-5'>
                              <span className='mt-1'>Line Manager:</span>
                              <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Problem Solving
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Identifying and addressing the root causes
                              of problems rather than addressing symptoms
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                        </td>
                        <td>
                          <div className='d-flex'>
                            <div className='flex-grow-1 d-flex pt-2'>
                              <span className='mt-1'>Self:</span>
                              <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                            </div>
                            <div className='flex-grow-1 d-flex pt-2 ms-5'>
                              <span className='mt-1'>Line Manager:</span>
                              <input type='number' disabled className='number-input border rounded ms-2' defaultValue={0} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className='section mt-4'>
                  <div className='section-header bg-tertiary p-2 text-light font-weight-600'>
                    Section C: Personal Development Plan
                  </div>
                  <div className='section-body'>
                    <table className='table ' >
                      <tr>
                        <th >Competency to Address:</th>
                        <th>Proposed Actions:</th>
                        <th>Responsibility:</th>
                        <th>Progress / Outcome:</th>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Financial Management
                            </textarea>
                          </div>
                        </td>
                        <td> <div>
                          <textarea disabled className='form-control'>
                          Find applicable course on Internal LMS system
                          </textarea>
                        </div></td>
                        <td> <div>
                          <textarea disabled className='form-control'>
                          Finish and pass the course. Apply knowledge accordingly.
                          </textarea>
                        </div>
                        </td>
                        <td>
                          <div>
                            <textarea className='form-control'>
                            </textarea>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                              Proposal Writing
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                            Find applicable course on Internal LMS system
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea disabled className='form-control'>
                            Finish and pass the course. Improvement in proposal writing.
                            </textarea>
                          </div>
                        </td>
                        <td>
                          <div>
                            <textarea className='form-control'>
                            </textarea>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="d-flex">
                    <input type="radio" name="hello" className="mb-4"/>
                    <div className="ms-2">I have finalised my progress capturing</div>
                  </div>
                  <button className='btn btn-secondary btn-sm py-1 my-1 mt-2  text-nowrap ms-auto me-3'>SAVE</button>
                  
                  <button className='btn btn-tertiary btn-sm py-1 my-1 mt-2  text-nowrap'>SUBMIT</button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </main >
    </>
  )
}
export default PerformanceContractForm;
