import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from '../../components/Filters/TempFilters';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX

// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const PerformanceDistribution = () => {

  const chartProps1 = {
    type: 'column',
    title: '',
    categories: ["1 - Not Achieved", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved", "TEA - Too Early to Assess"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        type: 'line',
        name: 'Net Movement',
        data: [0.0, 6.5, 7.9, 18.5, 1.0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
        marker: {
          lineWidth: 2,
          lineColor: '#5BBDCB',
          fillColor: '#5BBDCB'
        }
      }
    ]
  };

  const chartProps2 = {
    type: 'column',
    title: '',
    categories: ["1 - Not Achieved", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved", "TEA - Too Early to Assess"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        name: 'Female',
        data: [0, 7.1, 73.6, 18.4, 1.0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#4D91B6',
      },
      {
        name: 'Male',
        data: [0, 6.9, 74.0, 17.9, 1.2],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
      }
    ]
  };


  const chartProps3 = {
    type: 'column',
    title: '',
    categories: ["1 - Not Achieved", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved", "TEA - Too Early to Assess"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        name: 'African',
        data: [0.0, 63.7, 50.3, 44.4, 69.8],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#4D91B6',
      },
      {
        name: 'Indian',
        data: [0.0, 8.9, 14.6, 18.3, 14.0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
      },
      {
        name: 'Coloured',
        data: [0.0, 12.5, 17.5, 14.7, 14.0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
      },
      {
        name: 'White',
        data: [0.0, 14.9, 17.6, 22.6, 2.3],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
      }
    ]
  };



  const chartProps4 = {
    type: 'column',
    title: '',
    categories: ["1 - Not Achieved", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved", "TEA - Too Early to Assess"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        name: '00 - 02',
        data: [0.0, 26.3, 18.4, 13.9, 69.8],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#00164e',

      },
      {
        name: '03 - 05',
        data: [0.0, 24.2, 18.1, 18.3, 7.0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#74BBC9',
      },
      {
        name: '06 - 10',
        data: [0.0, 19.2, 24.6, 25.6, 11.6],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#6C9BBC',
      },
      {
        name: '11 - 20',
        data: [0.0, 19.6, 24.6, 29.9, 9.3],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: 'orange',
      },
      {
        name: '21 - 30',
        data: [0.0, 8.5, 9.2, 8.0, 2.3],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#547A91',
      },
      {
        name: '31+',
        data: [0.0, 2.1, 5.1, 4.3, 0.0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: 'green',
      },
    ]
  };



  const chartProps5 = {
    type: 'column',
    title: '',
    categories: ["1 - Not Achieved", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved", "TEA - Too Early to Assess"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        name: '18 - 24',
        data: [0.0, 11.0, 72.0, 13.9, 0.0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#00164e',

      },
      {
        name: '25 - 29',
        data: [0.0, 10.5, 72.7, 18.3, 1.5],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#74BBC9',

      },
      {
        name: '30 - 39',
        data: [0.0, 7.2, 72.9, 25.6, 1.6],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#6C9BBC',
      },
      {
        name: '40 - 49',
        data: [0.0, 5.5, 73.4, 39.9, 0.9],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: 'orange',
      },
      {
        name: '50 - 59',
        data: [0.0, 6.7, 75.2, 8.0, 0.4],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#547A91',
      },
      {
        name: '60+',
        data: [0.0, 4.8, 88.9, 4.3, 0.0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: 'green',
      },
    ]
  };


















  const getOptions = (props) => ({
    chart: {
      type: props.type,
      backgroundColor: 'white',
    },
    plotOptions: {
      column: {
        colorByPoint: props.colorByPoint
      },
      series: {
        borderRadius: '25%'
      }
    },
    colors: props.colors,
    title: {
      text: props.title
    },
    credits: {
      enabled: props.creditsEnabled
    },
    legend: {
      enabled: props.legendEnabled
    },
    yAxis: {
      title: {
        text: 'Percentages(%)',
      },
    },
    xAxis: {
      categories: props.categories,
      labels: {
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'scroll',
        },
        tickWidth: 0,
      },
    },
    series: props.series,
  });


  const getOptions2 = (props) => ({
    chart: {
      type: 'column'
    },
    title: {
      text: '',
      align: 'left'
    },
    xAxis: {
      categories: ["1 - Not Achieved", "2 - Partially Achieved", "3 - Fully Achieved", "4 - Exceptionally Achieved", "TEA - Too Early to Assess"]
    },
    colors: ["#67904B", "#547A91", "#DC8E18", "#A6CBDB", "#00164E"],
    yAxis: {
      min: 0,
      title: {
        text: 'Percentage (%)'
      },
      stackLabels: {
        shadow: false,
        enabled: false
      }
    },
    legend: {
      align: 'center',

      floating: false,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',

    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          style: {
            backgroundColor: 'white'
          }
        }
      }
    },
    series: [
      {
        name: 'B5',
        data: [0, 0.7, 1.0, 0.4, 2.3],
        dataLabels: {
          enabled: true,
          shadow: false,
          format: '{y}%',
          color: '#000000',
          padding: 0,
          textOutline: 'none',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        }
      },
      {
        name: 'B4',
        data: [0, 61.6, 42.2, 42.6, 34.9],
        dataLabels: {
          enabled: true,
          shadow: false,
          format: '{y}%',
          color: '#000000',
          padding: 0,
          textOutline: 'none',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        }
      },
      {
        name: 'B3',
        data: [0, 31.7, 41.9, 37.3, 51.2],
        dataLabels: {
          enabled: true,
          shadow: false,
          format: '{y}%',
          color: '#000000',
          padding: 0,
          textOutline: 'none',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        }
      },
      {
        name: 'B2',
        data: [0, 5.0, 10.1, 15.2, 9.3],
        dataLabels: {
          enabled: true,
          shadow: false,
          format: '{y}%',
          color: '#000000',
          padding: 0,
          textOutline: 'none',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        }
      },
      {
        name: 'B1',
        data: [0.0, 1.1, 4.8, 4.5, 2.3],
        dataLabels: {
          enabled: true,
          shadow: false,
          format: '{y}%',
          color: '#000000',
          padding: 0,
          textOutline: 'none',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        }
      }]

  });

  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"People Management/Performance Distribution"} />
      <main id="main" className="main">
        <div className='d-flex flex-column'>
          <div>
            <button className='btn btn-primary btn-sm py-1 me-2'>RESET</button>
            <button className='btn btn-primary btn-sm py-1'>REAPPLY</button>
          </div>
          <div>
            <TempFilters />
          </div>
        </div>
        <section className="section mt-3">
          <div className='row'>

            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card'>
                <div className="">
                  <h5 className="header-title">Performance Rating Distribution (# 3 996)</h5>
                </div>
                <div className='card-body'>
                  <div className={"d-flex justify-content-center"}>
                  </div>
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps1)} />
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card'>
                <div className="">
                  <h4 className="header-title">Band / Rating Rating Distribution</h4>
                </div>
                <div className='card-body'>
                  <div className={"d-flex justify-content-center"}>
                  </div>

                  <HighchartsReact highcharts={Highcharts} options={getOptions2()} />
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card'>
                <div className="">
                  <h4 className="header-title">Race / Rating Rating Distribution</h4>
                </div>
                <div className='card-body'>
                  <div className={"d-flex justify-content-center"}>
                  </div>
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps3)} />
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card'>
                <div className="">
                  <h4 className="header-title">Gender / Rating Rating Distribution</h4>
                </div>
                <div className='card-body'>
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps2)} />
                </div>
              </div>
            </div>




            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card'>
                <div className="">
                  <h5 className="header-title">Tenure / Rating Rating Distribution</h5>
                </div>
                <div className='card-body'>
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps4)} />
                </div>
              </div>
            </div>


            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card'>
                <div className="">
                  <h4 className="header-title">Age / Rating Rating Distribution</h4>
                </div>
                <div className='card-body'>
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps5)} />
                </div>
              </div>
            </div>



            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card mt-3'>

                <div className='card-body'>
                  <div>
                    <button className='btn btn-sm py-1 text-light bg-secondary'>
                    <i class="fa-solid fa-file-excel me-2"></i>
                      Export data Table
                    </button>
                  </div>
                  <br />
                  <table class="table  custom-table">
                    <thead>
                      <tr className='text-center'>
                        <th scope="col" className='text-start'>Business Area</th>
                        <th scope="col">Headcount</th>
                        <th scope="col"> Not Achived</th>
                        <th scope="col">Partially Achieved</th>
                        <th scope="col">Fully Achieved</th>
                        <th scope="col">Exceptionally Achieved</th>
                        <th scope="col">Too Early to Access</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr className="font-weight-600">
                        <td className="text-start ps-2">Properties Pty Ltd</td>
                        <td>30</td>
                        <td>0.0%</td>
                        <td>3.3%</td>
                        <td>76.7%</td>
                        <td>20.0%</td>
                        <td>0.0%</td>
                      </tr>

                      <tr>
                        <td>Properties Pty Ltd</td>
                        <td>30</td>
                        <td>0.0%</td>
                        <td>3.3%</td>
                        <td>76.7%</td>
                        <td>20.0%</td>
                        <td>0.0%</td>
                      </tr>


                      <tr className="font-weight-600">
                        <td className="text-start ps-2">Actuarial & Risk</td>
                        <td>98</td>
                        <td>0.0%</td>
                        <td>4.1%</td>
                        <td>78.6%</td>
                        <td>17.3%</td>
                        <td>0.0%</td>
                      </tr>



                      <tr>
                        <td>Actuarial & Risk</td>
                        <td>98</td>
                        <td>0.0%</td>
                        <td>4.1%</td>
                        <td>78.6%</td>
                        <td>17.3%</td>
                        <td>0.0%</td>
                      </tr>



                      <tr className="font-weight-600">
                        <td className="text-start ps-2">Business Development</td>
                        <td >231</td>
                        <td>0.0%</td>
                        <td>7.8%</td>
                        <td>75.3%</td>
                        <td>16.0%</td>
                        <td>0.9%</td>
                      </tr>

                      <tr>
                        <td>Africa Insurance</td>
                        <td>38</td>
                        <td>0.0%</td>
                        <td>0.0%</td>
                        <td>82.4%</td>
                        <td>15.5%</td>
                        <td>0.0%</td>
                      </tr>

                      <tr>
                        <td>Health (Pty) Ltd</td>
                        <td>193</td>
                        <td>0.0%</td>
                        <td>9.3%</td>
                        <td>73.6%</td>
                        <td>16.1%</td>
                        <td>1.0%</td>
                      </tr>


                      <tr className="font-weight-600">
                        <td className="text-start ps-2">Client & Adviser Experience</td>
                        <td>815</td>
                        <td>0.0%</td>
                        <td>98.2%</td>
                        <td>72.6%</td>
                        <td>17.5%</td>
                        <td>1.6%</td>
                      </tr>

                      <tr>
                        <td>Client & Adviser Experience</td>
                        <td>815</td>
                        <td>0.0%</td>
                        <td>98.2%</td>
                        <td>72.6%</td>
                        <td>17.5%</td>
                        <td>1.6%</td>
                      </tr>


                      <tr className="font-weight-600">
                        <td className="text-start ps-2">Client & Adviser Service Centre</td>
                        <td>1 007</td>
                        <td>0.0%</td>
                        <td>8.6%</td>
                        <td>72.5%</td>
                        <td>17.6%</td>
                        <td>1.3%</td>
                      </tr>

                      <tr>
                        <td>Client & Adviser Service Centre</td>
                        <td>1 007</td>
                        <td>0.0%</td>
                        <td>8.6%</td>
                        <td>72.5%</td>
                        <td>17.6%</td>
                        <td>1.3%</td>
                      </tr>


                      <tr className="font-weight-600" >
                        <td className="text-start ps-2">Finance</td>
                        <td >213</td>
                        <td>2.3%</td>
                        <td>74.2%</td>
                        <td>22.5%</td>
                        <td>0.9%</td>
                        <td>0.0%</td>
                      </tr>

                      <tr>
                        <td>Finance</td>
                        <td>213</td>
                        <td>2.3%</td>
                        <td>74.2%</td>
                        <td>22.5%</td>
                        <td>0.9%</td>
                        <td>0.0%</td>
                      </tr>


                      <tr className="font-weight-600">
                        <td className="text-start ps-2">Human Capital</td>
                        <td>98</td>
                        <td>0.0%</td>
                        <td>3.1%</td>
                        <td>81.6%</td>
                        <td>14.3</td>
                        <td>1.0%</td>
                      </tr>

                      <tr>
                        <td>Human Capital</td>
                        <td>98</td>
                        <td>0.0%</td>
                        <td>3.1%</td>
                        <td>81.6%</td>
                        <td>14.3</td>
                        <td>1.0%</td>
                      </tr>

                      <tr className="font-weight-600">
                        <td className="text-start ps-2">Information Technology</td>
                        <td>367</td>
                        <td>0.0%</td>
                        <td>7.1%</td>
                        <td>78.5%</td>
                        <td>13.6</td>
                        <td>0.0%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default PerformanceDistribution;
